<template>
  <div style="position: relative;" ref="rootDiv" >

      <!--  좌측 상단 라이브버튼    -->
      <div id="info-live-root" v-if="isShowLiveText" class="livePopMark" style="display :block">
        <img src="@/assets/images/icon_svg/ic_live_mark.svg"/>
      </div>

      <!-- 우측상단 사진캡처 버튼 -->
      <div ref="vodScreenShot" class="vodDown flex"  v-show="isPlayStatus && (isVod ? true : isLiveButtonShow )" :style="{'right' : captureRight + 'px'}" @click="onVideoCapture">
        <img src="@/assets/images/icon_svg/ic_screen_shot.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
      </div>

      <!-- 우측상단 전체화면 버튼 -->
      <div ref="popupFullScreen" class="vodDown flex" v-if="isLiveButtonShow && isPlayStatus" :style="{'right' : expandRight + 'px'}" @click="toggleFullScreen">
        <img v-if="!isFullScreen" src="@/assets/images/icon_svg/ic_full_bk.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
        <img v-if="isFullScreen" src="@/assets/images/icon_svg/ic_full_end.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
      </div>

      <!-- 라이브 우측하단 전체화면 버튼(팝업으로 띄움) -->
      <div v-if="isShowFullScreen && playerId != undefined && !isVod" @click="openFullScreenModal" class="right-bottom-fullscreen">
        <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
      </div>

      <!-- 다운로드 버튼 -->
      <div ref="vodDownload" class="vodDown flex"  v-show="isVod && isDownload && isPlayStatus"  :style="{'right' : downloadRight + 'px'}" @click="onVideoDownload">
        <img src="@/assets/images/icon_svg/ic_download_vod.svg" style="width: 2rem; height: 2rem;"/>
      </div>

      <!-- 삭제 버튼 -->
      <div class="vodDown flex" v-if="isVod && isDeleteButtonShow && isDelete && isPlayStatus" :style="{'right' : deleteRight + 'px'}" @click="onVideoDelete">
        <img src="@/assets/images/icon_svg/ic_delete_vod.svg" style="width: 1.8rem; height: 1.8rem;"/>
      </div>

      <!-- 오른쪽 하단 스피커버튼 -->
      <!-- 드론은 소리x -->
      <div v-if="isShowVolum&& !isVod && deviceType !== 'Drone'" @click="isSoundToggle" class="soundBox" >
        <img class="speekerSize" v-if="!muted" src="@/assets/images/icon_svg/ic_speaker_on.png" alt="icon" />
        <img class="speekerSize"  v-else src="@/assets/images/icon_svg/ic_speaker_off.png" alt="icon" />
      </div>

      <!-- AI 선택 버튼 (유저 라이센스에 따라 열림) -->
      <div v-if="false" ref="aiSelect" v-show="( this.$router.currentRoute.name=='vodPlay' || isAiMarkShow) && isPlayStatus" class="vod-ai-mark" id="ai-mark-root">
        <div class="vod-ai-btn">
          <input type="radio" v-model="selectAiDetecing" name="detecting" value ="object">
          <label>객체 감지</label>
          <input type="radio" v-model="selectAiDetecing" name="detecting" value ="fire" >
          <label>불 감지</label>
          <input type="radio" v-model="selectAiDetecing" name="detecting" :value ="null" >
          <label style="margin-right: 0px;">감지 해제</label>
        </div>
      </div>

      <div class="video-player video-player-box vjs-big-play-centered">
        <video
            ref="videoPlayer"
            class="video-js"
            id="videoPlayer"
            :controls="isControls"
            :autoplay="isAuto"
            @statechanged = "playerStateChanged">
        </video>
      </div>
  </div>
</template>



<script>
// import Vue from "vue";
// local files
// you have to put your scripts into the public folder.
// that way webpack simply copy these files as it is.
// Vue.loadScript("/js/jquery-2.2.4.min.js")

// cdn
// Vue.loadScript("https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY")
import { fetchVodDownloadUrl, fetchDeleteVod } from "@/api/contents";
import { fetchChannelCheckStream } from "@/api/channel";
import moment from "moment";
import {playerPopOpen2} from "@/util/popup";


export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    isVod: {
      type: Boolean,
      default: true,
    },
    isUser: {
      type: Boolean,
      default: true,
    },
    playerId: {
      type:String,
      default: null,
    },
    contentsId:{
      type:String,
      default: null,
    },
    videoType :{ //추후 ai디텍팅시에 디텍팅 타입을 받으면 된다.
      type : String,
      default : "orginal"
    },
    isAuto : { // 영상자동실행 vod는 false, live의경우 true
      type : Boolean,
      default : false,
    },
    isControls : { // 하단 컨트롤바 vod:true, live:false
      type : Boolean,
      default : true,
    },
    isLiveButtonShow: { // 왼쪽상단 live버튼
      type : Boolean,
      default : false,
    },
    isDeleteButtonShow: {
      type : Boolean,
      default : true,
    },
    isAiMarkShow:{
      type : Boolean,
      default : false,
    },

    deviceType: {
      type: String,
      default: null
    },
    // 현재 streamId(playerId)와, 선택한 streamId를 비교해서 소리를 들리게할지말지 결정
    selectStreamId: {
      type : String,
    },
    channelIndex: {
      type: Number,
      default: null
    },

    isShowVolum: {
      type: Boolean,
      default: true
    },
    isShowFullScreen: {
      type: Boolean,
      default:true
    },
    isShowLiveText: {
      type: Boolean,
      default:true
    }
    //isai-mark  샤용유무 값 받아서 표출할지말지 결정
    //ai this.$emit 을 사용해서 값 넘겨주기. (ai-object , ai-fire, ai-clear)
    //새창보기부분 표출(map, 현장정보) id값 받아서 플레이어 자식에 추가하기.
  },
  data() {
    return {
      isLoadedmetadata : false,
      interval : null,
      watingInterval: null,
      liveLoadError : false,
      player: null,
      muted: true,

      playerOptions: {
        // 기본 설정
        disablePictureInPicture: true, // Picture-in-Picture(작은 화면 모드) 기능 비활성화 (default: false)
        playbackRates: [0.5, 1.0, 2.0, 4.0], // 재생 속도 옵션 (VOD에서 사용)
        muted: true, // 비디오가 처음 재생될 때 음소거 상태로 시작 (default: false)
        volume: 0.5, // 초기 볼륨 설정 (0.0 ~ 1.0 사이 값, default: 1.0)
        autoplay: this.isAuto, // 자동 재생 여부 (true/false)
        language: "ko", // UI 언어 설정 (ISO 언어 코드, 예: "en", "ko")
        errorDisplay: false, // 재생 오류 발생 시 오류 메시지 표시 여부 (default: true)
        controls: this.isControls, // 컨트롤바 표시 여부 (true/false)

        controlBar: {
          // 컨트롤바 세부 설정
          playToggle: true, // 재생/일시정지 버튼 표시 (default: true)
          currentTimeDisplay: true, // 현재 재생 시간 표시 (default: true)
          timeDivider: true, // 현재 시간과 총 시간 사이 구분선 표시 (default: true)
          durationDisplay: true, // 총 비디오 길이 표시 (default: true)
          remainingTimeDisplay: false, // 남은 시간 표시 (default: true)
          progressControl: true, // 재생 바(progress bar) 표시 (default: true)
          liveDisplay: true, // 라이브 스트림 아이콘 표시 (default: true, 라이브 스트림 전용) 하지만 라이브는 컨트롤바 없긴함
          seekToLive: true, // 라이브 스트림에서 "Live" 버튼 표시 (default: false, 라이브 스트림 전용)
          playbackRateMenuButton: this.isVod, // 재생 속도 메뉴 표시 (VOD 전용, true/false)
          audioTrackButton: true, // 오디오 트랙 변경 버튼 표시 (default: false)
          fullscreenToggle: true, // 전체화면 버튼 표시 (default: true)
        },

        hls: true, // HLS 스트림 지원 활성화 (true/false, 기본 Video.js HLS 지원)
        downloadFile: true, // 다운로드 버튼 활성화 (추가 플러그인 필요, 기본 Video.js에는 없음)
        bigPlayButton: true, // 중앙의 큰 재생 버튼 표시 (default: true)
      },

      isFullScreen : false,
      selectAiDetecing : null,
      isPlayStatus : false,
      errorCount : 0,
      oldCurrentTimeError : 0,
      oldCurrentTime : 0,
      watingCount : 0,

      buttonDefaultRight: 10,
      buttonMargin : 16,
      buttonSize : 32,

      isDelete : !this.isUser,
      isDownload : this.isVod,
      isExpand : !this.isVod,

      playerAddDom : [],
    };
  },
  computed : {
    captureRight: function () {
      var right = 0;
      if(this.isVod) {
        right = this.buttonDefaultRight;
        if(this.isDelete && this.isDeleteButtonShow) {
          right = right + this.buttonMargin + this.buttonSize;
        }
        if(this.isDownload) {
          right = right + this.buttonMargin + this.buttonSize;
        }

      } else {
        right = this.buttonDefaultRight;
        if(this.isExpand) {
          right = right + this.buttonMargin + this.buttonSize;
        }
      }

      if(this.isFullScreen) {
        right = 0;
      }

      if(right == 0){
        right = this.buttonDefaultRight;
      }
      return right;
    },
    deleteRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    downloadRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = right + this.deleteRight;
        right = right + this.buttonMargin + this.buttonSize;
      }
      if(right == 0) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    expandRight: function () {
      var right = 0;
      if(this.isExpand) {
        right = this.buttonDefaultRight;
      }
      return right;
    }
  },
  mounted() {
    this.player = window.videojs( this.$refs.videoPlayer,this.playerOptions);
    this.player.on('error',this.playerError)
    this.player.on("ready", this.playerReadied)
    this.player.on("timeupdate", this.onPlayerTimeupdate)
    this.player.on("loadeddata", this.onPlayerLoadeddata)
    this.player.on("waiting",this.onPlayerWating);
    this.player.on("canplay",this.onCanPlay);

    // 동작중일떄 실행
    // this.player.on('progress',(data)=> {
    //   console.log("프로그래스중", data)
    // });

    this.player.on('play', this.playerStateChanged);
    this.player.on('pause',this.playerStateChanged);
    this.player.on('ended',this.playerStateChanged);


    // if(!this.isVod) {
    //   console.log("this is live!")
    //   this.interval = setInterval(() => {
    //     this.onPlayerCheckStatus();
    //   }, 3000);
    // }
    addEventListener("fullscreenchange", this.changeFullScreen);
  },

  beforeDestroy() {
    // if (this.player) {
    //   this.player.dispose(); // 컴포넌트가 제거될 때 플레이어 제거
    // }
    if(this.interval != null) {
      clearInterval(this.interval);
    }
    if(this.watingInterval != null) {
      clearInterval(this.watingInterval);
    }
  },
  watch: {
    selectAiDetecing(value) {
      this.$emit("onUpdateAiDetecting",value);//현재 동영상 시간, contentId, aiDetecting type 넘기기.
    },
    isUser(value) {
      this.isDelete = !value;
    },
    src() {
      this.isPlayStatus = false;
      this.getVideo();
    },
    selectStreamId: function(newValue){
      if(this.playerId != newValue ) {
        this.mute()
      } else {
        this.unMute()
      }
    },
  },
  methods: {
    openFullScreenModal() {
      playerPopOpen2("live", this.playerId,this.channelIndex);
    },
    isSoundToggle(){
      this.muted = !this.muted
      this.player.muted(this.muted)
      this.$emit("toggleSound",this.playerId)
    },
    changeMute(mute){
      this.muted = mute
      this.player.muted(this.muted)
    },
    mute(){
      this.changeMute(true)
    },
    unMute(){
      this.changeMute(false)
    },

    /**
     * 라이브 영상 상태 체크
     */
    onPlayerCheckStatus(){
      // vod 영상의 경우 상태체크 할 필요가 없음
      if(this.isVod){ return }
      fetchChannelCheckStream(this.playerId)
          .then((res) => {
            if (res.data.result !== 0) { return }
            if(res.data.data.bytesIn == 0 || res.data.data.bytesIn === null) { // 데이터를 안주고 있는경우 에러카운트 +1
              this.oldCurrentTimeError++;
            } else {
              this.oldCurrentTimeError = 0;
            }
            if( this.oldCurrentTimeError > 3){ // 에러카운트가 3이상일 경우 채널에서 삭제
              this.onPlayerRemove();
            }
          })
    },
    onPlayerReloadTimeout(){
      setTimeout(() => {
        this.reloadPlayer();
      }, 2000);
    },
    onVideoDownload(){
      if(this.contentsId != null) {
        if (!confirm(this.$t("alert-message-download-contents-player-message")) ) {
          return;
        }

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function(){
          if (this.readyState == 4 && this.status == 200){
            var filename = "";

            var disposition = xhr.getResponseHeader('Content-Disposition');

            if (disposition && disposition.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            //this.response is what you're looking for
            var a = document.createElement("a");
            var url = URL.createObjectURL(this.response)
            a.href = url;
            if(filename == "") {
              filename = `${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.mp4`;
            }
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        }
        xhr.open('GET', fetchVodDownloadUrl(this.contentsId));
        xhr.responseType = 'blob'; // !!필수!!
        xhr.setRequestHeader("x-token", localStorage.getItem("token"));
        xhr.setRequestHeader("Access-Control-Expose-Headers", "*");
        xhr.setRequestHeader( "Access-Control-Allow-Origin", "*");

        xhr.send(); // 파라미터 설정하는 부분이며 formData 설정 부분은 생략
      }
    },
    onVideoCapture(){
      // if (!confirm(this.$t("alert-message-download-contents-capture-message")) ) {
      //   return;
      // }

      let videoElement = this.$refs.videoPlayer;
      let canvas = document.createElement("canvas");
      canvas.crossOrigin = "anonymous";
      let ctx = canvas.getContext('2d');

      canvas.width = 640;
      canvas.height = 360;

      ctx.fillRect(0,0,canvas.width,canvas.height);
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height );
      ctx.crossOrigin = "anonymous";

      var link = document.createElement('a');
      link.download = `${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
    },
    onVideoDelete(){
      if (!confirm(this.$t("alert-message-delete-contents-message")) ) {
        return;
      }
      fetchDeleteVod(this.contentsId).then((res) => {
        if (res.data.result == 0) {
          this.$emit("contents-delete", this.contentsId)
        }
      });
    },

    toggleFullScreen(){
      this.$emit("toggleFullScreen", !this.isFullScreen);
    },
    changeFullScreen(){
      this.isFullScreen = !this.isFullScreen
    },

    getVideo() {
      if(this.player != null && this.player.src() != this.src) {
        this.player.src({
          src: this.src,
          type: 'application/x-mpegURL',
          withCredentials: false
        });
      }
    },


    onPlayerTimeupdate() {
      if(this.player == null) {
        return;
      }
      this.$emit("onTimeUpdate", this.player.currentTime(),this.contentsId);
    },

    /**
     * live가 실행준비가 완료 되었을때,
     * videoplayer 화면에 버튼들 돔에 추가
     * */
    onPlayerLoadeddata() {
      console.error("onPlayerLoadeddata error", this.player.error())
      if(this.player.error() != null) {
        this.isLoadedmetadata = false;
      } else {
        if(this.isAiMarkShow || this.$router.currentRoute.name == 'vodPlay'){
          if(this.$refs.aiSelect != undefined) {
            this.player.el_.appendChild(this.$refs.aiSelect);
          }
        }
        if(this.$router.currentRoute.name == 'vodPlay' || this.$router.currentRoute.name == 'VOD' || this.$router.currentRoute.name == 'livePlay'){
          this.player.el_.appendChild(this.$refs.vodScreenShot);
        }
        if(this.$router.currentRoute.name == 'livePlay'){
          this.player.el_.appendChild(this.$refs.popupFullScreen);
        }
      }
    },

    onPlayerRemove(){
      this.$emit('playerRemoveId',this.playerId)
    },

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("playerStateChanged", playerCurrentState)
      // 상태가 play 가능할 경우
      if(playerCurrentState.type == "play") {
        this.errorCount = 0;
        this.liveLoadError = false
        this.isLoadedmetadata = true;
        this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
      }

      // 정지된 상태일 경우
      if(this.isVod == false && playerCurrentState.type == "pause") {
        this.isLoadedmetadata = false;
        this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
      }
      if(playerCurrentState.type == "play" || playerCurrentState.type == "pause"){
        this.isPlayStatus = true;
        this.$emit("isCanPlay", this.playerId, playerCurrentState);
      }
    },

    reloadPlayer(){
      if(this.errorCount > 20) {
        this.onPlayerRemove();
        if(this.watingInterval !== null) {// 에러카운트가 20개 넘어갈시, 연결끊긴거라서 웨이팅인터벌 종료
          clearInterval(this.watingInterval)
        }
      }

      if(this.liveLoadError == true && this.isVod == false) {
        this.errorCount++;
        if(this.player != null) {
          this.player.src({
            src: null,
            type: 'application/x-mpegURL',
            withCredentials: false
          });
        }
        this.isPlayStatus = false;
        this.getVideo();
      }
    },

    reloadWating() {
      if(this.watingCount > 3) {
        this.onPlayerRemove();

        this.player.src({
          src: this.src,
          type: 'application/x-mpegURL',
          withCredentials: false
        });

        this.watingCount = 0
        clearInterval(this.watingInterval)
      }

      if(!this.isVod) {
        this.watingCount++
      }
    },

    playerError(){
      let error = this.player.error();
      let time = this.player.currentTime();

      switch (error.code) {
        case 2 : {
          if(this.isVod == false){
            /**
             * 라이브 일때는 삭제가 아닌 리로드하도록 코드 수정.
             * */
            this.playerReload()
          }else{
            this.onPlayerRemove();
          }
          break;
        }
        case 3 : {
          if(this.isVod){
            this.player.load();
            this.player.pause();
            this.player.currentTime(time + 1);
            this.player.play();
          }
          // else {
          //   this.playerReload()
          // }
          break;
        }
        case 4 : {
          this.playerReload()
          break;
        }
      }
    },

    // player is ready
    playerReadied(player) {

      this.getVideo();
      this.$emit("isReadyPlay",player);

    },
    playerReload(){
      this.liveLoadError = true;
      this.isLoadedmetadata = false;
      this.onPlayerReloadTimeout();
      this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
    },

    onPlayerWating(){
      if(this.watingInterval === null) {
        this.watingInterval = setInterval(() => {
          this.reloadWating()
        }, 2000);
      }

    },

    onCanPlay() {
      clearInterval(this.watingInterval)
      this.watingCount = 0
      this.liveLoadError = false
      this.isLoadedmetadata = true;
      this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
    }
  },
};
</script>



<style>

.right-bottom-fullscreen {
  position: absolute;
  bottom:10px;
  right: 10px;
  z-index: 10;
  line-height: 28px;
}
.soundBox {
  position: absolute;
  bottom:10px;
  right: 40px;
  z-index: 10;
  line-height: 28px;
}


.speekerSize {
  width: 20px;
}

/*비디오 다운로드*/
.vodDown{
  width: 36px;
  height: 36px;
  text-align: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  padding: 6px;
  color:black;
  border: 1px solid white;
}
.vodDown:hover{
  background: #94d9ef;
}
.video-js .vjs-volume-bar{
  margin: 1.35em 0em 1.35em 0.45em;
}

.vjs-slider-horizontal .vjs-volume-level:before{
  top: -0.3rem !important;
}
.video-js .vjs-play-progress:before{
  top: 15% !important;
  transform: translate(-0%, -40%);
}

.vjs-duration, .vjs-current-time, .vjs-time-divider {
  display: block !important;
}

.vjs-big-play-button > span.vjs-icon-placeholder{
  font-size: 3rem !important;
}

.video-player, .vjs-icon-placeholder, .vjs-volume-bar, .vjs-progress-control,.vjs-control-bar, .vjs-menu-button {
  font-size: 1.2rem !important;
}

.vjs-time-control {
  min-width: 0rem !important;
  display: block !important;
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
}
.vjs-fullscreen > #info-live-root > img {
  width: 8rem;
}

.livePlay > img {
  width: 6rem;
}
.livePopMark > img{width: 45px;}

.vjs-picture-in-picture-control {
  display: none !important;
}
</style>
